var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading || _vm.isEditorLoading),expression:"isLoading || isEditorLoading"}],staticClass:"add-edit-faq"},[_c('div',{staticClass:"add-edit-faq__title"},[_vm._v(_vm._s(_vm.isEditMode ? _vm.$t('faq.page.edit') : _vm.$t('faq.page.add')))]),_c('div',{staticClass:"add-edit-faq__sections"},[_c('div',{staticClass:"add-edit-faq-section"},[_c('div',{staticClass:"add-edit-faq-section__title"},[_vm._v(" "+_vm._s(_vm.$t('faq.page.title'))+" ")]),_c('div',{staticClass:"form-input add-edit-faq-section__form-input",class:{
          'form-input--full' : _vm.form.title,
          'form-input--error' : _vm.errors.title
        }},[_c('input',{attrs:{"type":"text"},domProps:{"value":_vm.form.title},on:{"input":function($event){return _vm.setForm({ key: 'title', value: $event.target.value })}}}),_c('span',{staticClass:"form-input__label"},[_vm._v(_vm._s(_vm.$t('faq.page.title_placeholder')))]),_vm._l((_vm.errors.title),function(error){return _c('div',{key:error,staticClass:"form-input__error",domProps:{"textContent":_vm._s(error)}})})],2)]),_c('div',{staticClass:"add-edit-faq-section"},[_c('div',{staticClass:"add-edit-faq-section__checkbox"},[_c('label',{staticClass:"input__item-checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.active),expression:"form.active"}],attrs:{"type":"checkbox","value":"all","required":""},domProps:{"checked":Array.isArray(_vm.form.active)?_vm._i(_vm.form.active,"all")>-1:(_vm.form.active)},on:{"change":function($event){var $$a=_vm.form.active,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="all",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "active", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "active", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "active", $$c)}}}}),_vm._m(0),_c('span',{staticClass:"input__item-checkbox__info"},[_c('span',{staticClass:"input__item-checkbox__title"},[_vm._v(" "+_vm._s(_vm.$t('faq.page.activity'))+" ")])])])])]),_c('div',{staticClass:"add-edit-faq-section"},[_c('div',{staticClass:"add-edit-faq-section__title"},[_vm._v(" "+_vm._s(_vm.$t('faq.page.sort_order'))+" ")]),_c('div',{staticClass:"form-input add-edit-faq-section__form-input",class:{
          'form-input--full' : _vm.form.pos,
          'form-input--error' : _vm.errors.pos
        }},[_c('input',{attrs:{"type":"number","inputmode":"numeric","pattern":"[0-9]*"},domProps:{"value":_vm.form.pos},on:{"input":function($event){return _vm.setForm({ key: 'pos', value: $event.target.value })}}}),_c('span',{staticClass:"form-input__label"},[_vm._v(_vm._s(_vm.$t('faq.page.sort_order_placeholder')))]),_vm._l((_vm.errors.pos),function(error){return _c('div',{key:error,staticClass:"form-input__error",domProps:{"textContent":_vm._s(error)}})})],2)]),_c('div',{staticClass:"add-edit-faq-section"},[_c('div',{staticClass:"add-edit-faq-section__title"},[_vm._v(" "+_vm._s(_vm.$t('faq.page.text'))+" ")]),_c('Editor',{attrs:{"api-key":"1ww3ylfiwhe7ppkar3quhs2ysm5wr3wja7fyrhtas7xnd117","init":{
          toolbar_mode: 'sliding',
          toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | h1 h2 h3 h4 h5 h6 | bullist numlist | code',
          menubar: '',
          plugins: ['lists', 'code'],
          init_instance_callback: _vm.handleInitEditor,
          setup: (editor) => {
            editor.on('Change', () => {
              _vm.setForm({ key: 'text', value: this.clearFontFamilyInSpan(editor.getContent()) })
            })
          },
        },"initial-value":_vm.form.text}}),_vm._l((_vm.errors.text),function(error){return _c('div',{key:error,staticClass:"form-input__error",domProps:{"textContent":_vm._s(error)}})})],2),_c('div',{staticClass:"add-edit-faq-section"},[_c('div',{staticClass:"add-edit-faq-section__form-col add-edit-faq-section__form-col--padding-0"},[_c('ButtonElement',{attrs:{"disabled":_vm.isBtnDisabled,"text":_vm.$t('faq.page.save')},on:{"click-button":_vm.saveFaq}})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"input__item-checkbox__check"},[_c('img',{attrs:{"src":require("@/assets/icons/icon-done.svg")}})])
}]

export { render, staticRenderFns }