<template>
  <div class="add-edit-faq" v-loading="isLoading || isEditorLoading">
    <div class="add-edit-faq__title">{{ isEditMode ? $t('faq.page.edit') : $t('faq.page.add') }}</div>
    <div class="add-edit-faq__sections">
      <div class="add-edit-faq-section">
        <div class="add-edit-faq-section__title">
          {{ $t('faq.page.title') }}
        </div>
        <div
          class="form-input add-edit-faq-section__form-input"
          :class="{
            'form-input--full' : form.title,
            'form-input--error' : errors.title
          }"
        >
          <input 
            :value="form.title"
            @input="setForm({ key: 'title', value: $event.target.value })" 
            type="text"
          >
          <span class="form-input__label">{{ $t('faq.page.title_placeholder') }}</span>
          <div
            v-for="error in errors.title"
            :key="error"
            class="form-input__error"
            v-text="error"
          />
        </div>
      </div>
      <div class="add-edit-faq-section">
        <div class="add-edit-faq-section__checkbox">
          <label class="input__item-checkbox">
          <input
            type="checkbox"
            value="all"
            required
            v-model="form.active"
          >
          <span class="input__item-checkbox__check">
            <img src="@/assets/icons/icon-done.svg">
          </span>
          <span class="input__item-checkbox__info">
            <span class="input__item-checkbox__title">
              {{ $t('faq.page.activity') }}
            </span>
          </span>
          </label>
        </div>
      </div>
      <div class="add-edit-faq-section">
        <div class="add-edit-faq-section__title">
          {{ $t('faq.page.sort_order') }}
        </div>
        <div
          class="form-input add-edit-faq-section__form-input"
          :class="{
            'form-input--full' : form.pos,
            'form-input--error' : errors.pos
          }"
        >
          <input 
            :value="form.pos"
            @input="setForm({ key: 'pos', value: $event.target.value })" 
            type="number" 
            inputmode="numeric" 
            pattern="[0-9]*"
          >
          <span class="form-input__label">{{ $t('faq.page.sort_order_placeholder') }}</span>
          <div
            v-for="error in errors.pos"
            :key="error"
            class="form-input__error"
            v-text="error"
          />
        </div>
      </div>
      <div class="add-edit-faq-section">
        <div class="add-edit-faq-section__title">
          {{ $t('faq.page.text') }}
        </div>
        <Editor
          api-key="1ww3ylfiwhe7ppkar3quhs2ysm5wr3wja7fyrhtas7xnd117"
          :init="{
            toolbar_mode: 'sliding',
            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | h1 h2 h3 h4 h5 h6 | bullist numlist | code',
            menubar: '',
            plugins: ['lists', 'code'],
            init_instance_callback: handleInitEditor,
            setup: (editor) => {
              editor.on('Change', () => {
                setForm({ key: 'text', value: this.clearFontFamilyInSpan(editor.getContent()) })
              })
            },
          }"
          :initial-value="form.text"
        />
        <div
          v-for="error in errors.text"
          :key="error"
          class="form-input__error"
          v-text="error"
        />
      </div>
    <div class="add-edit-faq-section">
      <div class="add-edit-faq-section__form-col add-edit-faq-section__form-col--padding-0">
        <ButtonElement
          :disabled="isBtnDisabled"
          :text="$t('faq.page.save')"
          @click-button="saveFaq"
        />
        </div>
    </div>
  </div>
  </div>
</template>

<script>
import ButtonElement from '../../components/elements/ButtonElement'
import { mapState, mapMutations, mapActions } from "vuex"
import Editor from '@tinymce/tinymce-vue' 
export default {
  name: 'AddEditFAQ',
  components: {
    Editor,
    ButtonElement,
  },
  data() {
    return {
      isLoading: false,
      isEditorLoading: true
    }
  },
  computed: {
    ...mapState('faq', ['form', 'errors', 'pagination']),
    isEditMode() {
      return this.$route.query.mode === 'edit'
    },
    isBtnDisabled() {
      return (this.form.pos === null || this.form.pos === "") || !this.form.text || !this.form.title
    }
  },
  methods: {
    ...mapMutations('faq', ['setForm', 'setPagination', 'clearForm', 'replaceFaq', 'setFormError']),
    ...mapActions('faq', ['addFaq', 'fetchFaq', 'updateFaq']),
    clearFontFamilyInSpan(text) {
      const tempContainer = document.createElement('div');
      tempContainer.innerHTML = text;
      const spans = tempContainer.querySelectorAll('span');
      
      spans.forEach((span) => {
        const style = span.getAttribute('style');
        if (!style) return;

        const styles = style.split(';');
        let newStyle = '';

        styles.forEach((style) => {
          const pair = style.split(':');
          if (pair.length !== 2) return
          
          const propertyName = pair[0].trim()
          const propertyValue = pair[1].trim()

          if (propertyName === 'font-family') {
              return;
          }
          newStyle += propertyName + ':' + propertyValue + ';';
        });

        span.setAttribute('style', newStyle);
      });

      return tempContainer.innerHTML;
    },
    handleInitEditor() {
      this.isEditorLoading = false
    },
    clearState() {
      this.clearForm()
      this.$router.push({ query: {} }).catch(() => {})
    },
    closeDialog() {
      this.clearState()
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: null })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: false })
    },
    handleErrors(response) {
      if (response && response.response && response.response.data) {
        const { errors } = response.response.data

        Object.keys(errors).map(errorKey => [
          this.setFormError({
            key: errorKey,
            value: errors[errorKey]
          })
        ])
        this.isLoading = false
      }
    },
    saveFaq() {
      this.isLoading = true 

      if (this.isEditMode) {
        const newFaq = {
          pos: this.form.pos,
          title: this.form.title,
          text: this.form.text,
          active: this.form.active,
          id: this.form.id,
        }

        this.updateFaq(newFaq).then((updatedFaq) => {
          this.replaceFaq(updatedFaq)
          
          this.$notify({
            message: this.$t('faq.page.update_success'),
            type: 'success'
          })

          this.closeDialog()
        }).catch(this.handleErrors).finally(() => {
          this.isLoading = false
        })
      } else {
        this.addFaq(this.form).then(() => {
          this.fetchFaq({ params: { per_page: this.pagination.perPage, page: 1 }}).then(() => {
            this.$notify({
              message: this.$t('faq.page.add_success'),
              type: 'success'
            })
            this.closeDialog()
          }).finally(() => {
            this.isLoading = false
          }) 
        }).catch(this.handleErrors)
      }
    },
  },
  destroyed() {
    this.clearState()
  },
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.selector.multi-search-selector {
  position: relative;
  width: 100%;

  @include below($sm) {
      max-width: 100%;
  }

  .selector__content {
      background: $backgroundInput;
      border: 1px solid $border;
      border-radius: 2px;
      height: 5rem;
      position: relative;
      padding: 0.7rem 1.5rem;
      width: 100%;
      display: flex;
      align-items: center;
  }

  .selector__title {
      font-size: 1.6rem;
      line-height: 1.4rem;
      color: $basicBlack;
      margin-bottom: 0.2rem;
  }

  .selector__value {
      font-size: 1.6rem;
      line-height: 1.9rem;
  }

  .selector__arrow {
      cursor: pointer;
      position: absolute;
      right: 1.5rem;
      top: 1.5rem;

      img {
          transition: 0.3s ease-out;
      }

      &--open {
          img {
              transform: rotate(180deg);
          }
      }
  }

  .selector__options {
      position: absolute;
      top: calc(100% + 1rem);
      left: 0;
      width: 100%;
      background: $backgroundInput;
      border: 1px solid $border;
      border-radius: 2px;
      z-index: 2;
      overflow: auto;
      max-height: 30rem;

      &-item {
          cursor: pointer;
          transition: 0.3s ease-out;
          padding: 0.7rem 1.5rem;
          margin: .3rem 0;

          &:hover {
              background: var(--primary);
              color: $basicWhite;
          }

          &--active {
              background: var(--primary);
              color: $basicWhite;
          }
      }
  }

  @include below(769px) {
    .selector__content {
          width: 100%;
      }
  }
}


.add-edit-faq-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__calendar {
    display: flex;
  }

  &__form-input {
    margin-top: 0;
    position: relative;
    width: 100%;
    transition: .3s;
  }

  .promo-values-group {
    display: flex;
    // flex-wrap: wrap;
    justify-content: flex-start;
    input {
      display: none;
    }
    .promo-amount {
      &.active {
        background-color: #D6E0F2;
      }
      padding: 1rem 3.5rem;
      border-radius: 0px 2px 2px 0px;
      border: 1px solid var(--grey-3, #BFC2C6);
      cursor: pointer;
    }
  }

  &__title {
    font-family: 'Roboto';
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.3rem;
    text-align: left;
  }
  &__description {
    font-family: 'Roboto';
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.9rem;
    text-align: left;
  }
  &__checkbox {
    .input__item-checkbox__title {
      font-family: 'Roboto';
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.6rem;
      text-align: left;
    }
  }
  &__form {
    &-block {
      margin: 0;
    }
    &-row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -0.75rem;
    }
    &-col {
      width: 50%;
      padding: 0 0.75rem;
      margin-bottom: 0;
      .button {
        height: 5rem;
        width: 100%;
        font-family: 'Roboto';
        font-size: 1.6rem;
        font-weight: 700;
      }
     &--padding-0 {
      padding: 0;
     }
    }
  }
  &__selector{
    &-wrapper {
      display: flex;
      gap: 1.2rem;
      .selector {
        width: 100%;
      }
      .promo-button {
        border: none;
        width: 5rem;
        height: 5rem;
        background-color: var(--primary);
        border-radius: 5px;
        color: #fff;
        background-image: url('../../assets/icons/icon-cross-straight.svg');
        background-repeat: no-repeat;
        background-position: center center;
      }
    } 
    .selector__content {
      width: 100%;
    }
  }
  &__info {
    padding: 1.5rem;
    border-radius: 2px;
    border: 1px solid #D1D4D8;
    overflow: auto;
    min-height: 12.5rem;
    color: #333;
    font-family: 'Roboto';
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    resize: none;
    &:focus {
      outline: none;
      
    }
 
  }
}

.add-edit-faq {
  padding: 0.5rem;
  color: #333;

  &-section {
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    // input[type="number"] {
    // -moz-appearance: textfield;
    // }

  }

  &__sections {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  &__title {
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.5rem;
    color: $basicBlack;
    margin-bottom: 1.5rem;
  }

  &__desc {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $basicBlack;
    margin-bottom: 3rem;
  }
  &__section {
    &-title {
      font-family: Roboto;
      font-size: 20px;
      font-weight: 700;
      line-height: 23px;
      letter-spacing: 0px;
      text-align: left;
    }
    &__form-input {
      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
      }

    //   input[type="number"] {
    //   -moz-appearance: textfield;
    // }

    }
  }


  @include below(577px) {
    padding: 32px 0 0;

    &__title {
      font-size: 18px;
      text-align: center;
      line-height: 1.4;
      margin-bottom: 16px;

      br {
        display: none;
      }
    }

    &__desc {
      font-size: 14px;
      line-height: 1.4;
      margin-bottom: 16px;
      text-align: center;
    }

    &__show-settings {
      margin-bottom: 16px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }

      span {
        font-size: 14px;
      }
    }

    &__form {

      .form-input {
        margin-bottom: 0;
      }

      &-title {
        font-size: 16px;
        margin-bottom: 12px;
      }

      &-block {
        margin-bottom: 16px;
      }

      &-row {
        margin-right: -16px;
        margin-left: -16px;
      }

      &-col {
        width: 100%;
        padding-right: 16px;
        padding-left: 16px;
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-agree {
        margin-top: 0;
      }

      select {
        height: 5rem;
      }

      &-buttons {
        margin-top: 16px;

        .button {
          font-size: 14px;
          line-height: 1.4;
          height: auto;
          padding: 8px 12px;

          &:first-of-type {
            width: calc(50% - 6px);
            margin-right: 12px;
          }

          &:last-of-type {
            width: calc(50% - 6px);
          }
        }
      }
    }
  }
}

.tox {
  z-index: 9999 !important;
}
</style>
